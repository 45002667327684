export function showToast(type, message) {
    const toastContainer = document.querySelector('#toastContainer');
    const toast = document.createElement('div');
    toast.classList.add('toast');
    if (type == 'success') {
        toast.classList.add('success');
    };
    if (type == 'error') {
        toast.classList.add('danger');
    };
    toast.innerHTML = `
        <p>${message}</p>
    `;
    toast.classList.add('slide-in-bottom');
    toastContainer.appendChild(toast);
    
    setTimeout(() => {
        toast.classList.add('slide-out-blurred-right');
    }, 2000);
    setTimeout(() => {
        toast.remove();
    }, 3000);
};