import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    doc,
    setDoc,
    query,
    collection,
    orderBy,
    limit,
    startAfter,
    getDoc,
    getDocs,
    updateDoc,
    arrayRemove,
    where
} from 'firebase/firestore';
import { 
    getAuth, 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut
} from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import { showToast } from './modules/toasts.js';

// Fitrebase config
const firebaseConfig = {
    apiKey: "AIzaSyBbv55z6LdtZ7vEgyZQa6T36x_SHiYsTfE",
    authDomain: "portfolder-26b06.firebaseapp.com",
    projectId: "portfolder-26b06",
    storageBucket: "portfolder-26b06.appspot.com",
    messagingSenderId: "50198180089",
    appId: "1:50198180089:web:69ddd0760c9c0d5606b228"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Global variables
let parametersSet = {};
let showingOrder = 'desc';
let startDoc;
let renderQueue;
let profileOpen = false;
let categoriesOpen = false;
let colorsOpen = false;
let appliedFilter = {
    type: '',
    id: ''
};

// Defining used elements
const closeSubmitBannerButton = document.querySelector('#closeSubmitBannerButton');
const descOrderButton = document.querySelector('#descOrderButton');
const ascOrderButton = document.querySelector('#ascOrderButton');
const loadMoreButton = document.querySelector('#loadMore');
const categorySelectButton = document.querySelector('#categorySelectButton');
const colorSelectButton = document.querySelector('#colorSelectButton');
const allCategoriesButton = document.querySelector('#allCategoriesButton');
const anyColorsButton = document.querySelector('#anyColorsButton');

const submitBanner = document.querySelector('#submitBanner');
const categoryFilterBox = document.querySelector('#categoryFilterBox');
const colorFilterBox = document.querySelector('#colorFilterBox');

const overlayDialog = document.querySelector('#overlayDialog');
const loginForm = document.querySelector('#loginForm');
const signupForm = document.querySelector('#signupForm');
const resetForm = document.querySelector('#resetForm');
const signupButton = document.querySelector('#signupSwitchButton');
const loginButton = document.querySelector('#loginSwitchButton');
const loginSecondaryButton = document.querySelector('#loginSecondarySwitchButton');
const resetButton = document.querySelector('#resetSwitchButton');
const cancelButtons = document.querySelectorAll('#cancelButton');

const profileButton = document.querySelector('#profileButton');
const profileDropdown = document.querySelector('#profileDropdown');
const currentUserIndicator = document.querySelector('#currentUserIndicator');
const logoutButton = document.querySelector('#logoutButton');

const signupEmail = document.querySelector('#signupEmail');
const signupPassword = document.querySelector('#signupPassword');
const signupSubmitButton = document.querySelector('#signupButton');

const loginEmail = document.querySelector('#loginEmail');
const loginPassword = document.querySelector('#loginPassword');
const loginSubmitButton = document.querySelector('#loginSubmitButton');
const loginWithGoogleButton = document.querySelector('#loginWithGoogleButton');

const resetEmail = document.querySelector('#resetEmail');
const resetSubmitButton = document.querySelector('#resetSubmitButton');

const submitPortfolioByUserButton = document.querySelector('#submitPortfolioByUserButton');
const submitDialog = document.querySelector('#submitDialog');
const portfolioLink = document.querySelector('#portfolioLink');
const submitPortfolioFormButton = document.querySelector('#submitPortfolioFormButton');
const cancelSubmitButton = document.querySelector('#cancelSubmitButton');

const seeNotificationsButton = document.querySelector('#seeNotificationsButton');

const openBookmarksButton = document.querySelector('#openBookmarksButton');
const bookmarksDialog = document.querySelector('#bookmarksDialog');
const closeBookmarksButton = document.querySelector('#closeBookmarksButton');
const bookmarksList = document.querySelector('#bookmarksList');




// User observer
onAuthStateChanged(auth, (user) => {
    if (user) {
        
    } else {
        
    }
});



// Get initial portfolios on the page
getParameters();
getInitialPortfolios(showingOrder);



// Profile button functionality
profileButton.addEventListener('click', () => {
    const user = auth.currentUser;
    if (user !== null) {
        if (profileDropdown.classList.contains('hidden')) {
            profileOpen = true;
            profileDropdown.classList.remove('hidden');
            currentUserIndicator.innerText = user.email;
        } else {
            profileOpen = false;
            profileDropdown.classList.add('hidden');
        }
    } else {
        overlayDialog.classList.remove('hidden');
    };
});

// Closing profile dropdown by clicking elsewhere on page

// Auth windows functionality
loginButton.addEventListener('click', () => {
    loginForm.classList.remove('hidden');
    signupForm.classList.add('hidden');
    resetForm.classList.add('hidden');
});
loginSecondaryButton.addEventListener('click', () => {
    loginForm.classList.remove('hidden');
    signupForm.classList.add('hidden');
    resetForm.classList.add('hidden');
});
signupButton.addEventListener('click', () => {
    loginForm.classList.add('hidden');
    signupForm.classList.remove('hidden');
    resetForm.classList.add('hidden');
});
resetButton.addEventListener('click', () => {
    loginForm.classList.add('hidden');
    signupForm.classList.add('hidden');
    resetForm.classList.remove('hidden');
});
cancelButtons.forEach(button => {
    button.addEventListener('click', () => {
        overlayDialog.classList.add('hidden');
    });
});


// Sign up functionality
signupSubmitButton.addEventListener('click', async function() {
    if (signupEmail.value && signupPassword.value) {
        try {
            await createUserWithEmailAndPassword(auth, signupEmail.value, signupPassword.value);
            await createUserInDatabase(auth.currentUser.uid);
            await createInitialCollection(auth.currentUser.uid);
            signupEmail.value = '';
            signupPassword.value = '';
            overlayDialog.classList.add('hidden');
            showToast('success', 'Successfully signed up!');
            getInitialPortfolios(showingOrder);
        } catch (error) {
            showToast('error', error);
        };
    } else {
        showToast('error', 'Please enter email and password');
    };
});

// Sign in with password functionality
loginSubmitButton.addEventListener('click', async function() {
    if (loginEmail.value && loginPassword.value) {
        try {
            await signInWithEmailAndPassword(auth, loginEmail.value, loginPassword.value);
            loginEmail.value = '';
            loginPassword.value = '';
            overlayDialog.classList.add('hidden');
            showToast('success', 'Successfully logged in!');
            getInitialPortfolios(showingOrder);
        } catch (error) {
            showToast('error', error);
        };
    } else {
        showToast('error', 'Please enter email and password');
    };
});

// Sign in with Google functionality
loginWithGoogleButton.addEventListener('click', async function() {
    try {
        await signInWithPopup(auth, provider);
        await createUserInDatabase(auth.currentUser.uid);
        await createInitialCollection(auth.currentUser.uid);
        getInitialPortfolios(showingOrder);
        overlayDialog.classList.add('hidden');
        showToast('success', 'Successfully logged in!');
    } catch (error) {
        showToast('error', error);
    };
});

// Reset password functionality
resetSubmitButton.addEventListener('click', async function() {
    if (resetEmail.value) {
        try {
            await sendPasswordResetEmail(auth, resetEmail.value);
            resetEmail.value = '';
            overlayDialog.classList.add('hidden');
            showToast('success', 'Check your email to reset password!');
        } catch (error) {
            showToast('error', error);
        };
    } else {
        showToast('error', 'Please enter account email');
    };
});


// Sign out functionality
logoutButton.addEventListener('click', async function() {
    try {
        await signOut(auth);
        getInitialPortfolios(showingOrder);
        showToast('success', 'Successfully signed out!');
        profileDropdown.classList.add('hidden');
    } catch (error) {
        showToast('error', error);
    }
});


// Notifications functionality
seeNotificationsButton.addEventListener('click', () => {
    showToast('success', 'No new notifications');
});


// Bookmarks listing functionality
openBookmarksButton.addEventListener('click', () => {
    profileDropdown.classList.add('hidden');
    bookmarksDialog.classList.remove('hidden');
    listBookmarks();
});
closeBookmarksButton.addEventListener('click', () => {
    bookmarksDialog.classList.add('hidden');
});


// Banner functionality
closeSubmitBannerButton.addEventListener('click', () => {
    submitBanner.classList.add('slide-out-blurred-right');
    setTimeout(() => {
        submitBanner.classList.add('hidden');
    }, 1000);
});

// Filter buttons functionality
categorySelectButton.addEventListener('click', () => {
    colorFilterBox.classList.add('hidden');
    colorsOpen = false;
    if (categoriesOpen) {
        categoriesOpen = false;
        categoryFilterBox.classList.add('hidden');
    } else {
        categoriesOpen = true;
        categoryFilterBox.classList.remove('hidden');
    };
});
colorSelectButton.addEventListener('click', () => {
    categoryFilterBox.classList.add('hidden');
    categoriesOpen = false;
    if (colorsOpen) {
        colorsOpen = false;
        colorFilterBox.classList.add('hidden');
    } else {
        colorsOpen = true;
        colorFilterBox.classList.remove('hidden');
    };
});

// Closing context menus by clicking elsewhere on page
document.addEventListener('click', function(event) {
    // filter boxes
    if (categoriesOpen || colorsOpen) {
        if (event.target.tagName !== 'BUTTON') {
            categoriesOpen = false;
            colorsOpen = false;
            categoryFilterBox.classList.add('hidden');
            colorFilterBox.classList.add('hidden');
        };
    };

    // profile dropdown
    // if (profileOpen) {
    //     if (event.target.getAttribute('scope') !== 'profileDropdown') {
    //         profileOpen = false;
    //         profileDropdown.classList.add('hidden');
    //     };
    // };
});


// Changing order of portfolios by switch
loadMoreButton.addEventListener('click', () => {
    const user = auth.currentUser;
    if (user !== null) {
        getFurtherPortfolios(showingOrder);
    } else {
        showToast('error', 'Please log in to load more portfolios!');
    }
});
descOrderButton.addEventListener('click', ()=>{
    ascOrderButton.classList.remove('active-switch');
    descOrderButton.classList.add('active-switch');
    showingOrder = 'desc';
    getInitialPortfolios(showingOrder);
});
ascOrderButton.addEventListener('click', ()=>{
    descOrderButton.classList.remove('active-switch');
    ascOrderButton.classList.add('active-switch');
    showingOrder = 'asc';
    getInitialPortfolios(showingOrder);
});


// Submit portfolio by user
submitPortfolioByUserButton.addEventListener('click', () => {
    submitDialog.classList.remove('hidden');
    profileDropdown.classList.add('hidden');
});
cancelSubmitButton.addEventListener('click', () => {
    portfolioLink.value = '';
    submitDialog.classList.add('hidden');
});
submitPortfolioFormButton.addEventListener('click', () => {
    submitPortfolio();
    portfolioLink.value = '';
    submitDialog.classList.add('hidden');
});


async function submitPortfolio(){
    if (auth.currentUser !== null) {
        if (portfolioLink.value) {
            const newPortfolio = {
                uuid: uuidv4(),
                status: 'submitted',
                submitter: auth.currentUser.uid,
                author_location: '',
                time_created: Date.now(),
                time_verified: '',
                public_link: portfolioLink.value,
                snapshot_link: '',
                cover_link: '',
                category: [],
                badges: [],
                palette: [],
                likes: [],
                metadata: []
            };
            try {
                await setDoc(doc(db, "portfolios", newPortfolio.uuid), newPortfolio);
                showToast('success', 'Portfolio submitted!');
            } catch (error) {
                showToast('error', error);
            };
        } else {
            showToast('error', 'Please enter portfolio link!');
        };
    } else {
        showToast('error', 'Please log in to submit portfolio!');
    };
};




// Function to get parameters set
async function getParameters(){
    parametersSet = {};
    try {
        const querySnapshot = await getDocs(collection(db, "parameters"));
        querySnapshot.forEach((doc) => {
            parametersSet[doc.id] = [];
            for (const key in doc.data()) {
                if (doc.data().hasOwnProperty(key)) {
                  parametersSet[doc.id].push(doc.data()[key]);
                };
            };
        });
        populareFilters();
    } catch (error) {
        console.log(error);
    };
};

// Function to get initial portfolios
async function getInitialPortfolios(order){
    document.querySelector('main').innerHTML = '';
    renderQueue = [];
    let queryRef;

    if (appliedFilter.type == 'category') {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), where("category", "array-contains", appliedFilter.id), orderBy("time_verified", order), limit(6));
    };
    if (appliedFilter.type == 'color') {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), where("palette", "array-contains", appliedFilter.id), orderBy("time_verified", order), limit(6));
    };
    if (!appliedFilter.type && !appliedFilter.id) {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), orderBy("time_verified", order), limit(6));
    };

    try {
        const querySnapshot = await getDocs(queryRef);
        startDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
            renderQueue.push(doc.data());
        });
        renderPortfoliosFromQueue();
        checkFurtherPortfolios(showingOrder);
    } catch (error) {
        console.log(error);
        showToast('error', error);
    };
};

// Function to get further portfolios
async function getFurtherPortfolios(order){
    renderQueue = [];
    let queryRef;

    if (appliedFilter.type == 'category') {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), where("category", "array-contains", appliedFilter.id), orderBy("time_verified", order), limit(3), startAfter(startDoc));
    };
    if (appliedFilter.type == 'color') {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), where("palette", "array-contains", appliedFilter.id), orderBy("time_verified", order), limit(3), startAfter(startDoc));
    };
    if (!appliedFilter.type && !appliedFilter.id) {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), orderBy("time_verified", order), limit(3), startAfter(startDoc));
    };

    try {
        const querySnapshot = await getDocs(queryRef);
        startDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
            renderQueue.push(doc.data());
        });
        renderPortfoliosFromQueue();
        checkFurtherPortfolios(showingOrder);
    } catch (error) {
        console.log(error);
        showToast('error', error);
    };
};

// Function to render portfolios based on render queue
function renderPortfoliosFromQueue(){
    if (renderQueue.length <= 3) {
        const additionalRenderedRow = document.createElement('div');
        additionalRenderedRow.classList.add('feed-row');
        document.querySelector('main').appendChild(additionalRenderedRow);
        renderQueue.forEach((portfolio) => {
            createPortfolioElement(portfolio, additionalRenderedRow);
        });
    } else {
        const firstRenderedRow = document.createElement('div');
        const secondRenderedRow = document.createElement('div');
        firstRenderedRow.classList.add('feed-row');
        secondRenderedRow.classList.add('feed-row');
        document.querySelector('main').appendChild(firstRenderedRow);
        document.querySelector('main').appendChild(secondRenderedRow);
    
        renderQueue.forEach((portfolio) => {
            if (renderQueue.indexOf(portfolio) < 3) {
                createPortfolioElement(portfolio, firstRenderedRow);
            } else {
                createPortfolioElement(portfolio, secondRenderedRow);
            };
        });
    };
};


function createPortfolioElement (portfolioObject, targetContainer) {
    const renderedPortfolio = document.createElement('article');
    renderedPortfolio.setAttribute('id', portfolioObject.uuid);
    renderedPortfolio.innerHTML = `
    <div class="card-top-nav">
        <a href="${portfolioObject.public_link}" target="_blank" rel="noopener noreferrer"><button type="button" class="card-button"><span class="icon ion--open-outline icon-l"></span></button></a>
    </div>
        <img src="https://firebasestorage.googleapis.com/v0/b/portfolder-26b06.appspot.com/o/cover%2F${portfolioObject.uuid}.webp?alt=media" class="card-image">
    <div class="card-bottom-nav">
        <button type="button" class="card-button" id="bookmarkButton"><span class="icon ion--bookmark-outline icon-l"></span></button>
        <button type="button" class="card-button" id="likeButton"><span class="icon ion--heart-outline icon-l"></span></button>
    </div>
    `;
    // Add event listeners to action buttons
    const likeButton = renderedPortfolio.querySelector('#likeButton');
    const bookmarkButton = renderedPortfolio.querySelector('#bookmarkButton');
    // Check if portfolio is liked
    if (auth.currentUser && portfolioObject.likes.includes(auth.currentUser.uid)) {
        likeButton.classList.add('liked');
        likeButton.classList.add('heartbeat');
    };
    // Check if portfolio is bookmarked
    if (auth.currentUser) {
        !async function () {
            const user = auth.currentUser;
            const collections = [];
            // Getting collections set
            try {
                const bookmarksRef = query(collection(db, "collections"), where ("author", "==", user.uid));
                const querySnapshot = await getDocs(bookmarksRef);
                querySnapshot.forEach((doc) => {
                    collections.push(doc.data());
                });
            } catch (error) {
                showToast('error', error);
            };
            // Indicating if portfolio is bookmarked by user
            if (collections[0].items.includes(portfolioObject.uuid)) {
                bookmarkButton.classList.add('bookmarked');
                bookmarkButton.classList.add('jello-vertical');
            };
        }();
    };

    likeButton.addEventListener('click', () => likePortfolio(portfolioObject, likeButton));
    bookmarkButton.addEventListener('click', () => bookmarkPortfolio(portfolioObject, bookmarkButton));

    renderedPortfolio.classList.add('tilt-in-top');
    targetContainer.appendChild(renderedPortfolio);
};

// function to do a request and test if there are more portfolios in the database
async function checkFurtherPortfolios (order){
    let queryRef;

    if (appliedFilter.type == 'category') {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), where("category", "array-contains", appliedFilter.id), orderBy("time_verified", order), startAfter(startDoc));
    };
    if (appliedFilter.type == 'color') {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), where("palette", "array-contains", appliedFilter.id), orderBy("time_verified", order), startAfter(startDoc));
    };
    if (!appliedFilter.type && !appliedFilter.id) {
        queryRef = query(collection(db, "portfolios"), where ("status", "==", 'live'), orderBy("time_verified", order), startAfter(startDoc));
    };

    try {
        const querySnapshot = await getDocs(queryRef);
        if (querySnapshot.docs.length > 0) {
            loadMoreButton.classList.remove('hidden');
        } else {
            loadMoreButton.classList.add('hidden');
        };
    } catch (error) {
        console.log(error);
        showToast('error', error);
    };
};

allCategoriesButton.addEventListener('click', () => {
    // Highlight selected filter
    categoryFilterBox.querySelectorAll('button').forEach(button => {
        button.classList.remove('active-switch');
    });
    allCategoriesButton.classList.add('active-switch');
    document.querySelector('#categoriesIndicator').innerText = 'all';
    // Set appliaed filter to defaultcategory
    appliedFilter.type = '';
    appliedFilter.id = '';
    // Close filter box
    categoryFilterBox.classList.add('hidden');
    // Apply filter
    getInitialPortfolios(showingOrder);
});
anyColorsButton.addEventListener('click', () => {
        // Highlight selected filter
        colorFilterBox.querySelectorAll('button').forEach(button => {
            button.classList.remove('active-switch');
        });
        anyColorsButton.classList.add('active-switch');
        document.querySelector('#colorsIndicator').innerText = 'any';
        // Set appliaed filter to defaultcategory
        appliedFilter.type = '';
        appliedFilter.id = '';
        // Close filter box
        colorFilterBox.classList.add('hidden');
        // Apply filter
        getInitialPortfolios(showingOrder);
});

// Function to like portfolio
async function likePortfolio(portfolio, button){
    const user = auth.currentUser;
    if (user !== null) {
        if (portfolio.likes.includes(user.uid)) {
            portfolio.likes = portfolio.likes.filter(id => id !== user.uid);
            button.classList.remove('liked');
            button.classList.remove('heartbeat');
            showToast('success', 'Portfolio unliked!');
        } else {
            portfolio.likes.push(user.uid);
            button.classList.add('liked');
            button.classList.add('heartbeat');
            showToast('success', 'Portfolio liked!');
        };
        try {
            await updateDoc(doc(db, "portfolios", portfolio.uuid), {
                likes: portfolio.likes
            });
        } catch (error) {
            showToast('error', error);
        };
    } else {
        showToast('error', 'Please log in to like portfolios!');
    };
};

// Function to bookmark portfolio
async function bookmarkPortfolio(portfolio, button){
    const user = auth.currentUser;
    if (user !== null) {
        const collections = [];
        // Getting collections set
        try {
            const bookmarksRef = query(collection(db, "collections"), where ("author", "==", user.uid));
            const querySnapshot = await getDocs(bookmarksRef);
            querySnapshot.forEach((doc) => {
                collections.push(doc.data());
            });
        } catch (error) {
            showToast('error', error);
        };
        // Check portfolio is not already saved
        if (!collections[0].items.includes(portfolio.uuid)) {
            collections[0].items.push(portfolio.uuid);
            try {
                await updateDoc(doc(db, "collections", collections[0].uuid), {
                    items: collections[0].items
                });
                showToast('success', 'Portfolio bookmarked!');
            } catch (error) {
                showToast('error', error);
            };
            button.classList.add('jello-vertical');
            button.classList.add('bookmarked');
        } else {
            showToast('success', 'Portfolio already bookmarked!');
        };
    } else {
        showToast('error', 'Please log in to save portfolios!');
    };
};


// Function to list bookmarked portfolios
async function listBookmarks(){
    const user = auth.currentUser;
    bookmarksList.innerHTML = '';
    if (user !== null) {
        const collections = [];
        // Getting collections set
        try {
            const bookmarksRef = query(collection(db, "collections"), where ("author", "==", user.uid));
            const querySnapshot = await getDocs(bookmarksRef);
            querySnapshot.forEach((doc) => {
                collections.push(doc.data());
            });
            // Listing all items in all collections
            collections.forEach(collection => {
                if (collection.status === 'live' && collection.items.length > 0) {
                    collection.items.forEach(item => {
                        !async function(){
                            const itemRef = doc(db, "portfolios", item);
                            try {
                                const itemSnapshot = await getDoc(itemRef);
                                bookmarkedPortfolio = itemSnapshot.data();
                                // Rendering portfolio
                                const renderedBookmark = document.createElement('div');
                                renderedBookmark.setAttribute('id', bookmarkedPortfolio.uuid);
                                renderedBookmark.classList.add('bookmark');
                                renderedBookmark.innerHTML = `
                                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolder-26b06.appspot.com/o/cover%2F${bookmarkedPortfolio.uuid}.webp?alt=media" class="bookmark-image">
                                    <p>${bookmarkedPortfolio.public_link}</p>
                                    <div class="controls-block">
                                        <button type="button" id="removeBookmarkButton"><span class="icon ion--trash-outline icon-l"></span>&nbsp; Delete</button>
                                        <button type="button" id="exploreBookmarkButton"><span class="icon ion--open-outline icon-l"></span>&nbsp; Explore</button>
                                    </div>
                                `;
                                // Event listeners for buttons
                                const exploreBookmarkButton = renderedBookmark.querySelector('#exploreBookmarkButton');
                                const removeBookmarkButton = renderedBookmark.querySelector('#removeBookmarkButton');

                                exploreBookmarkButton.addEventListener('click', () => {
                                    window.open(bookmarkedPortfolio.public_link, '_blank');
                                });
                                removeBookmarkButton.addEventListener('click', async function() {
                                    try {
                                        const collectionRef = doc(db, "collections", collection.uuid);
                                        await updateDoc(collectionRef, {
                                            items: arrayRemove(renderedBookmark.getAttribute('id'))
                                        });
                                        renderedBookmark.classList.add('fade-out-bck');
                                        setTimeout(() => {
                                            bookmarksList.removeChild(renderedBookmark);
                                        }, 1000);
                                    } catch (error) {
                                        showToast('error', error);
                                    };
                                });
                                renderedBookmark.classList.add('tilt-in-top');
                                bookmarksList.appendChild(renderedBookmark);
                            } catch (error) {
                                showToast('error', error);
                            };
                        }();
                    });
                };
            });
        } catch (error) {
            showToast('error', error);
        };
    } else {
        showToast('error', 'Please log in to see bookmarks!');
    };
};

// Function to populate filters
function populareFilters(){
    // Category filter
    parametersSet.categories.forEach(category => {
        const filterButton = document.createElement('button');
        filterButton.classList.add('switch-button');
        filterButton.setAttribute('id', category.uuid);
        filterButton.innerHTML = category.display_name;
        categoryFilterBox.appendChild(filterButton);

        filterButton.addEventListener('click', () => {
            // Highlight selected filter
            categoryFilterBox.querySelectorAll('button').forEach(button => {
                button.classList.remove('active-switch');
            });
            filterButton.classList.add('active-switch');
            document.querySelector('#categoriesIndicator').innerText = category.display_name;
            // Reset color filter
            colorFilterBox.querySelectorAll('button').forEach(button => {
                button.classList.remove('active-switch');
            });
            anyColorsButton.classList.add('active-switch');
            document.querySelector('#colorsIndicator').innerText = 'any';
            // Set appliaed filter to category
            appliedFilter.type = 'category';
            appliedFilter.id = filterButton.getAttribute('id');
            // Close filter box
            categoryFilterBox.classList.add('hidden');
            // Apply filter
            getInitialPortfolios(showingOrder);
        });
    });
    // Color filter
    parametersSet.colors.forEach(color => {
        const filterButton = document.createElement('button');
        filterButton.classList.add('switch-button');
        filterButton.setAttribute('id', color.uuid);
        filterButton.innerHTML = color.display_name;
        colorFilterBox.appendChild(filterButton);

        filterButton.addEventListener('click', () => {
            // Highlight selected filter
            colorFilterBox.querySelectorAll('button').forEach(button => {
                button.classList.remove('active-switch');
            });
            filterButton.classList.add('active-switch');
            document.querySelector('#colorsIndicator').innerText = color.display_name;
            // Reset category filter
            categoryFilterBox.querySelectorAll('button').forEach(button => {
                button.classList.remove('active-switch');
            });
            allCategoriesButton.classList.add('active-switch');
            document.querySelector('#categoriesIndicator').innerText = 'all';
            // Set appliaed filter to color
            appliedFilter.type = 'color';
            appliedFilter.id = filterButton.getAttribute('id');
            // Close filter box
            colorFilterBox.classList.add('hidden');
            // Apply filter
            getInitialPortfolios(showingOrder);
        });
    });
};

// Function to create user entry
async function createUserInDatabase(userUid) {
    const docRef = doc(db, "users", userUid);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return;
        } else {
            const newUser = {
                uuid: userUid,
                status: 'live',
                account_type: 'starter',
                industry_role: '',
                preferences: {},
                notifications: [],
                metadata: []
            };
            await setDoc(doc(db, "users", userUid), newUser);
        };
    } catch (error) {
        showToast('error', error);
    };
};

// Function to create default bookmarks collection for user
async function createInitialCollection(userUid) {
    const bookmarksRef = query(collection(db, "collections"), where ("author", "==", userUid));
    try {
        // Checking if there are any existing collections for the user
        const snapshot = await getDocs(bookmarksRef);
        if (snapshot.empty) {
            const newCollection = {
                uuid: uuidv4(),
                name: 'Bookmarked',
                description: 'My collection of bookmarked portfolios',
                author: userUid,
                status: 'live',
                type: 'private',
                items: [],
                metadata: []
            };
            // If no existing collections, create a new collection with default values
            await setDoc(doc(db, "collections", newCollection.uuid), newCollection);
        } else {
            return;
        };
    } catch (error) {
        showToast('error', error);
    };
};